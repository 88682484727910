import constant from "./constant.json";

export function isAmount(value) {
  return /^\d*(\.\d{0,2})?$/.test(value);
}

export function setCookie(name, value) {
  document.cookie = name + "=" + value + ";path=/";
}
export function setCookiePath(name, value) {
  document.cookie = name + "=" + value + ";path=" + window.location.href;
}
export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document?.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export function removeCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

// Prevents changing the input value on mouse scroll (works in input type="number")
export const inputScroll = (e) => {
  e.target.blur();
  e.stopPropagation();
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

// Prevents [-, +, e, E] to input
export const blockInvalidChar = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
export function bindDollar(value) {
  return `$${Number(value)?.toFixed(2)}`;
}

export function countryFilter() {
  return { phoneCode: "+1", currency: "$" };
}

export const DateFormat = "MM-dd-yyy";

export const genderOption = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" }
];
export const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" }
];
export const statusOptions2 = [
  { label: "Pending", value: "pending" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" }
];
export const ReferralStatusOptions = [
  { label: "Both", value: "on" },
  // { label: "Off", value: "off" },
  { label: "Sender", value: "sender" },
  { label: "Receiver", value: "received" }
];
export const bonusReferralStatusOptions = [
  { label: "Used", value: "used" },
  { label: "Pending", value: "pending" }
];
export const LoginType = [
  { label: constant.loginType.label.atm, value: "atm" },
  { label: constant.loginType.label.kiosk, value: "kioskTopUp" }
];
export const invoiceStatusOptions = [
  { label: "Estimate", value: "estimate" },
  { label: "Initiated", value: "initiated" },
  { label: "Unpaid", value: "unpaid" },
  { label: "Paid", value: "paid" },
  { label: "Delivered Unpaid", value: "delivered_unpaid" },
  { label: "Completed", value: "completed" }
];
export const kioskStatusOptions = [
  { label: "Created", value: "created" },
  { label: "Selected", value: "selected" },
  { label: "In Stock", value: "inStock" },
  { label: "Sold Out", value: "soldOut" },
  { label: "Returned", value: "returned" }
];
export const kioskStatusOptions2 = [
  { label: "In Stock", value: "inStock" },
  { label: "Returned", value: "returned" },
  { label: "Location", value: "soldOut" }
];
export const typeOptions = [
  { label: "Invoice", value: "invoice" },
  { label: "Return Invoice", value: "return_invoice" }
];
export const format1 = "MM/DD/YYYY";
export const format2 = "MM/DD/YYYY hh:mm A";

export function paymentLabelClass(name) {
  switch (name) {
    case "points":
      return "label-blue";
    case "earning":
      return "label-green";
    case "withdraw":
      return "label-red";
    default:
      return "label-blue";
  }
}

export function statusLabelClass(name) {
  switch (name) {
    case "active":
      return "label-green";
    case "inactive":
      return "label-red";
    case "pending":
      return "label-orange";
    case "estimate":
      return "label-red";
    case "initiated":
      return "label-red";
    case "unpaid":
      return "label-red";
    case "delivered_unpaid":
      return "label-red";
    case "paid":
      return "label-green";
    case "completed":
      return "label-blue";
    case "created":
      return "label-blue";
    case "selected":
      return "label-orange";
    case "inStock":
      return "label-green";
    case "soldOut":
      return "label-red";
    case "return_invoice":
      return "label-red";
    default:
      return "label-green";
  }
}

export function userTypeLable(name) {
  switch (name) {
    case "superAdmin":
      return "Super Admin";
    case "superUser":
      return "Super User";
    case "admin":
      return "Distributor";
    case "user":
      return "Distributor User";
    case "operator":
      return "Operator";
    case "operator_user":
      return "Operator User";
    default:
      return "";
  }
}

export function stringAvatar(name = "") {
  if (!name) return;
  if (name.split(" ")[1]) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
    };
  } else {
    return {
      children: `${name.split(" ")[0][0]}`
    };
  }
}

export const roundFix = (a) => Math.round(a * 100) / 100;

export function errorMessage() {
  return "Network request failed";
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat(bytes / Math.pow(k, i)).toFixed(dm)} ${sizes[i]}`;
}
export const SUPERACCESS = ["superAdmin", "superUser"];
export const DISTRIBUTORACCESS = ["admin", "user"];
export const OPERATORACCESS = ["operator", "operator_user"];

export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
export const StatementstatusOptions = [
  { label: "Paid", value: "paid" },
  { label: "Unpaid", value: "unpaid" }
];
export const ChartOptions = [
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" }
];
export function uploadProgress(data) {
  return Math.round((100 * data.loaded) / data.total);
}

export const ratioList = [
  { label: "65-70", value: 65 },
  { label: "70-75", value: 70 },
  { label: "75-80", value: 75 },
  { label: "80-85", value: 80 },
  { label: "85-90", value: 85 }
];

export const isEmptyObject = (objectName) => {
  return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
};
export const isEmptyArray = (arrayName) => {
  return Array.isArray(arrayName) && !arrayName.length;
};
export function LabelClass(name) {
  switch (name) {
    case "points":
      return "label-green";
    case "deposit":
      return "label-blue";
    case "withdraw":
      return "label-red";
    default:
      return "label-blue";
  }
}

export const paymentType = [
  {
    value: "points",
    label: "Money"
  },
  {
    value: "earning",
    label: "Earning"
  },
  {
    value: "withdraw",
    label: "Withdraw"
  },
  {
    value: "EtoP",
    label: "Earning to Money"
  }
];
export const paymentSource = [
  {
    value: "atm",
    label: constant.loginType.label.atm
  },
  {
    value: "transfer",
    label: "Transfer"
  },
  {
    value: "bet",
    label: "Bet"
  },
  {
    value: "registration",
    label: "Registration Bonus"
  },
  {
    value: "kioskTopUp",
    label: constant.loginType.label.kiosk
  },
  {
    value: "referral",
    label: "Referral"
  }
];
